/* You can add global styles to this file, and also import other style files */

@media only screen and (min-width: 1321px) {
  .info-box7-block {
    height: 310px;
  }
}

@media only screen and (max-width: 1320px) {
  .info-box7-block {
    height: 320px;
  }
}

@media only screen and (max-width: 1140px) {
  .info-box7-block {
    height: 320px;
  }
}

@media only screen and (max-width: 960px) {
  .info-box7-block {
    height: 300px;
  }
}

@media only screen and (max-width: 720px) {
  .info-box7-block {
    height: 320px;
  }
}

@media only screen and (max-width: 540px) {
  .info-box7-block {
    height: 320px;
  }
}

//List
ol.list-type-i {
  list-style-type: lower-roman;
}

ol.list-type-i li {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}

//Mat Checkbox in Modal
.modal-content .mat-checkbox label {
  white-space: pre-wrap;
}

.modal-content .mat-checkbox-inner-container {
  margin-top: 0.5rem !important;
}

.cdk-overlay-container {
  z-index: 1999;
}

.navbar-nav.navbar-right .nav-item .alert {
  line-height: 1rem !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

//Alert
#isApplicationSavedAlert {
  display: none;
}
// Datatable hide empty
.dataTables_empty{
  display: none !important;
}

